@import url('https://fonts.googleapis.com/css2?family=Gloock&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

@font-face {
    font-family: 'Breathe Fire'; /* Name of the font */
    src: url('./fonts/Breathe\ Fire\ IV.otf') format('opentype'); /* Path to the font file */
  }
  @font-face {
    font-family: 'bgothm'; /* Name of the font */
    src: url('./fonts/bgothm.ttf') format('truetype'); /* Path to the font file */
  }

  @font-face {
    font-family: 'coprgtb'; /* Name of the font */
    src: url('./fonts/COPRGTB.TTF') format('truetype'); /* Path to the font file */
  }

  @font-face {
    font-family: 'T1Z'; /* Name of the font */
    src: url('./fonts/T1ZIRBALTRIAL-REGULAR-BF64D4406897D27.OTF') format('opentype'); /* Path to the font file */
  }

  @font-face {
    font-family: 'SWISS'; /* Name of the font */
    src: url('./fonts/SWISSKO.TTF') format('truetype'); /* Path to the font file */
  }

  C:\Users\Abdul\Desktop\Code\hauseh\src\fonts\SWISSKO.TTF


.gloock {
    font-family: 'Gloock', serif;
}

.SourceSans {
  font-family: "Source+Sans+3", sans-serif;
}

.bgothm {
  font-family: 'bgothm', serif;
}


.breathe-fire {
    font-family: 'Breathe Fire', sans-serif; /* Use the font family name */
  }


  .coprgtb {
    font-family: 'coprgtb', sans-serif; /* Use the font family name */
  }

  .t1z {
    font-family: 'T1Z', sans-serif; /* Use the font family name */
  }

  .swiss {
    font-family: 'SWISS', sans-serif; /* Use the font family name */
  }

  @font-face {
    font-family: 'Aeonik';
    src: url('./fonts/AeonikTRIAL-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Aeonik';
    src: url('./fonts/AeonikTRIAL-RegularItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Aeonik';
    src: url('./fonts/AeonikTRIAL-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Aeonik';
    src: url('./fonts/AeonikTRIAL-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }