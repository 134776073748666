.container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 4px;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  position: relative;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
}

.container img {
  flex: 0 0 300px; /* Increased from 140px */
  width: 300px;
  max-width: 300px;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .container img {
    flex: 0 0 180px;
    width: 180px;
    max-width: 180px;
    height: 180px;
  }
}

@media (max-width: 768px) {
  .container img {
    flex: 0 0 150px;
    width: 150px;
    max-width: 150px;
    height: 150px;
  }
}